<template>
  <BaseDialog
    :show="true"
    width="610px"
    :btn1Loading="loading"
    title="編輯基本設定"
    @close="$emit('close')"
    @confirm="onSubmit"
  >
    <BaseElForm
      ref="formRef"
      v-loading="loading"
      label-position="top"
      :model="formData"
      :rules="formRules"
      @submit.native.prevent
    >
      <BaseElFormItem style="margin-bottom: 16px" label="收費對象設定：" prop="target">
        <div class="inline-flex flex-col">
          <BaseElRadioGroup
            v-model="formData.target"
            style="margin-bottom: 10px"
            @change="onTargetChange"
          >
            <div class="radio-group" @change="resetMemberTag">
              <BaseElRadio label="all">所有會員</BaseElRadio>
              <BaseElRadio label="advance">特定會員收費</BaseElRadio>
            </div>
          </BaseElRadioGroup>
          <div v-if="formData.target === 'advance'">
            <div>
              <div>包含</div>
              <BaseElFormItem ref="memberExclude" prop="memberExclude">
                <MemberTagSelect
                  :data="tags.filter((i) => !find(formData.memberInclude, { id: i.id }))"
                  placeholder="選擇需收費的會員"
                  multiple
                  preserveNull
                  :model.sync="formData.memberExclude"
                  @change="resetItem(['memberInclude', 'memberExclude'])"
                />
              </BaseElFormItem>
            </div>
            <div class="flex flex-col">
              <div>排除</div>
              <BaseElFormItem ref="memberInclude" prop="memberInclude">
                <MemberTagSelect
                  :data="tags.filter((i) => !find(formData.memberExclude, { id: i.id }))"
                  placeholder="選擇不需收費的會員"
                  multiple
                  preserveNull
                  :model.sync="formData.memberInclude"
                  @change="resetItem(['memberInclude', 'memberExclude'])"
                />
              </BaseElFormItem>
            </div>
          </div>
        </div>
      </BaseElFormItem>

      <div v-if="useFeatures.useReservationCashback">
        <BaseElFormItem prop="enableCashbackDeduction">
          <template #label>
            <FormItemTooltipLabel
              label="回饋金折抵設定"
              :tooltipWidth="200"
              class="title"
            >
              開啟後，預約服務項目可使用回饋金折抵結帳金額。
            </FormItemTooltipLabel>
          </template>
          <BaseElSwitch
            v-model="formData.enableCashbackDeduction"
            active-text="開啟"
            inactive-text="關閉"
            @change="onCashbackDeductionChange"
          />
        </BaseElFormItem>
        <div v-if="formData.enableCashbackDeduction">
          <BaseElFormItem prop="cashbackDeductionPercent">
            <template #label>
              <FormItemTooltipLabel
                label="回饋金折抵上限 (%)"
                :tooltipWidth="200"
                class="title"
              >
                回饋金折抵上限計算方式：結帳總和扣除堂票折抵後的金額，乘上 回饋金折抵上限比例。
              </FormItemTooltipLabel>
            </template>
            <BaseElInput
              v-model="formData.cashbackDeductionPercent"
            />
          </BaseElFormItem>
          <BaseElFormItem label="附加服務開放回饋金折抵" prop="enableServiceAttachCashbackDeduction">
            <BaseElSwitch
              v-model="formData.enableServiceAttachCashbackDeduction"
              active-text="開啟"
              inactive-text="關閉"
            />
          </BaseElFormItem>
        </div>
      </div>
    </BaseElForm>
  </BaseDialog>
</template>

<script>
import { defineComponent, onBeforeMount, computed } from 'vue'
import { useBaseForm } from '@/use/useForm'
import MemberTagSelect from '@/components/Select/MemberTagSelect.vue'
import BaseDialog from '@/components/Dialog/BaseDialog.vue'
import formUtils from '@/utils/form'
import store from '@/store'
import { useShop } from '@/use/shop'
import { find, get } from 'lodash'
import { UpdateReservationCheckoutConfig } from '@/api/reservation'
import { passValue } from '@/utils/helper'
import { noEmptyRules, isDigitRules, rangeRules, integerRules } from '@/validation'
import FormItemTooltipLabel from '@/components/Form/FormItemTooltipLabel.vue'
import notifyMessage from '@/config/notifyMessage'
import { usePermissions } from '@/use/permissions'

export default defineComponent({
  name: 'EditReservationCheckoutSettingsModal',
  components: { MemberTagSelect, BaseDialog, FormItemTooltipLabel },
  props: {
    configData: { type: Object, default: () => ({}) },
  },
  setup (props, { emit }) {
    const { shopId } = useShop()
    const { formRef, formData, initFormData, loading, checkForm } = useBaseForm()
    const { checkAction } = usePermissions()
    const useFeatures = computed(() => {
      return {
        useReservationCashback: checkAction('adminView.member.shopCashback'),
      }
    })
    const tags = computed(() => store.getters['memberTag/tags'] || [])
    const formRules = computed(() => {
      return {
        cashbackDeductionPercent: [noEmptyRules(), isDigitRules(), integerRules(), rangeRules(0, 100)],
      }
    })

    const submitData = computed(() => {
      return {
        shopId: shopId.value,
        freeMemberConfig: {
          enableIncludeTag: formData.memberInclude.length > 0,
          includeTagIds: formData.memberInclude.map((i) => i.id),
          enableExcludeTag: formData.memberExclude.length > 0,
          excludeTagIds: formData.memberExclude.map((i) => i.id),
        },
        cashbackDeductionPercent: formData.cashbackDeductionPercent / 100,
        enableCashbackDeduction: formData.enableCashbackDeduction,
        enableServiceAttachCashbackDeduction: formData.enableServiceAttachCashbackDeduction,
      }
    })

    const onSubmit = async () => {
      if (loading.value) return
      loading.value = true
      if (!await checkForm(formRef.value)) {
        loading.value = false
        return
      }
      const [, errMsg] = await UpdateReservationCheckoutConfig(submitData.value)
      if (errMsg) {
        window.$message.error(errMsg)
        loading.value = false
        return
      }
      window.$message.success(notifyMessage.updateSuccess)
      emit('refresh')
      emit('close')
    }

    const onTargetChange = (val) => {
      resetItem(['memberInclude', 'memberExclude'])
      formData.memberInclude = []
      formData.memberExclude = []
    }

    const resetItem = (refs) => {
      formUtils.clearValidate(formRef.value, refs)
    }

    const resetMemberTag = () => {
      formData.memberInclude = []
      formData.memberExclude = []
    }

    const onCashbackDeductionChange = (val) => {
      // if (!val) {
      //   formData.cashbackDeductionPercent = 0
      //   formData.enableServiceAttachCashbackDeduction = false
      // }
    }

    const syncFormData = () => {
      const config = get(passValue(props.configData), 'freeMemberConfig')
      if (config) {
        initFormData({
          target: config.enableIncludeTag || config.enableExcludeTag ? 'advance' : 'all',
          memberInclude: tags.value.filter((i) => config.includeTagIds.includes(i.id)),
          memberExclude: tags.value.filter((i) => config.excludeTagIds.includes(i.id)),
          cashbackDeductionPercent: get(props.configData, 'cashbackDeductionPercent', 0) * 100,
          enableCashbackDeduction: get(props.configData, 'enableCashbackDeduction') || false,
          enableServiceAttachCashbackDeduction: get(props.configData, 'enableServiceAttachCashbackDeduction') || false,
        })
      }
    }

    onBeforeMount(async () => {
      loading.value = true
      await store.dispatch('memberTag/getAllTags', { shopId: shopId.value })
      initFormData({
        target: 'all',
        memberInclude: [],
        memberExclude: [],
      })

      syncFormData()
      loading.value = false
    })

    return {
      tags,
      onSubmit,
      formRef,
      formRules,
      formData,
      loading,
      resetItem,
      resetMemberTag,
      find,
      submitData,
      onTargetChange,
      onCashbackDeductionChange,
      useFeatures,
    }
  },
})
</script>

<style lang="postcss" scoped>
.radio-group {
  @apply flex flex-col gap-[8px];
}
</style>
