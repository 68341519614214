<template>
  <div>
    <BaseElRadio :label="label" class="label" style="line-height: 24px">
      <div class="inline-flex items-center">
        <span>{{ title }}</span>
        <el-tooltip v-if="!disabledHelp" placement="right">
          <div slot="content">
            <slot name="help" />
          </div>
          <MaterialIcon size="21">help_outline_round</MaterialIcon>
        </el-tooltip>
      </div>
    </BaseElRadio>

    <slot />

    <div v-if="hint" class="hint">
      提示：{{ hint }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'RadioWithInput',
  props: {

    label: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    hint: {
      type: String,
      default: '',
    },
    disabledHelp: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style lang="postcss" scoped>
.hint {
  @apply text-gray-60 leading-[17px] text-sm font-normal;
}

.help-icon {
  transform: translateY(3px);
}
</style>
