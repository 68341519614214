<template>
  <section class="section-block">
    <SectionTitle title="基本設定" @edit="modal.edit = true" />
    <BaseElForm label-position="left" label-width="220px">
      <BaseElFormItem label="收費對象設定"> {{ displayData.feeTarget }} </BaseElFormItem>
      <div v-if="useFeatures.useReservationCashback">
        <BaseElFormItem label="回饋金折抵設定"> {{ displayData.enabledCashbackDiscount }} </BaseElFormItem>
        <BaseElFormItem label="回饋金折抵上限 (%)"> {{ displayData.cashbackDiscountRate }} </BaseElFormItem>
        <BaseElFormItem v-if="displayData.enabledAttachServiceCashbackDiscount && get(configData, 'enableCashbackDeduction')" label="附加服務開放回饋金折抵"> {{ displayData.enabledAttachServiceCashbackDiscount }}</BaseElFormItem>
      </div>
    </BaseElForm>

    <EditReservationCheckoutSettingsModal
      v-if="modal.edit"
      :config-data="configData"
      @close="modal.edit = false"
      @refresh="$emit('refresh')"
    />
  </section>
</template>

<script>
import { computed, defineComponent, reactive } from 'vue'
import SectionTitle from '@/components/Title/SectionTitle.vue'
import EditReservationCheckoutSettingsModal from './EditReservationCheckoutSettingsModal.vue'
import { map, get, isEmpty } from 'lodash'
import { useReservationCheckout } from '@/use/reservationCheckout'
import { usePermissions } from '@/use/permissions'

export default defineComponent({
  name: 'ReservationCheckoutSettingsBolck',
  components: { SectionTitle, EditReservationCheckoutSettingsModal },
  props: {
    configData: { type: Object, default: () => ({}) },
  },
  setup (props) {
    const modal = reactive({
      edit: false,
    })

    const { checkAction } = usePermissions()

    const { feeTarget } = useReservationCheckout()
    const useFeatures = computed(() => {
      return {
        useReservationCashback: checkAction('adminView.member.shopCashback'),
      }
    })
    const displayFeeTarget = computed(() => feeTarget(props.configData))

    const rateConversion = (rate) => {
      // rate 最小為 0 最大為 1
      // 將 rate 轉換為 0 ~ 100 的數值，並無條件捨去
      return Math.floor(rate * 100)
    }

    const displayData = computed(() => {
      if (!get(props.configData, 'id')) {
        return {
          feeTarget: '尚未設定',
          enabledCashbackDiscount: '關閉',
          cashbackDiscountRate: '-',
        }
      }
      const cashbackDeductionPercent = rateConversion(get(props.configData, 'cashbackDeductionPercent'))
      return {
        feeTarget: displayFeeTarget.value,
        enabledCashbackDiscount: get(props.configData, 'enableCashbackDeduction') ? '開啟' : '關閉',
        cashbackDiscountRate: `${cashbackDeductionPercent} %`,
        enabledAttachServiceCashbackDiscount: get(props.configData, 'enableServiceAttachCashbackDeduction') ? '開啟' : '關閉',
      }
    })
    return { modal, displayData, useFeatures, get }
  },
})
</script>

<style lang="postcss" scoped></style>
