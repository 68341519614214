<template>
  <BaseDialog title="編輯發票設定" width="600px" @close="$emit('close')" @confirm="onSubmit">
    <BaseElForm ref="formRef" :model="formData" :rules="formRules" label-position="top">
      <BaseElFormItem label="開放串接第三方發票" prop="enable">
        <BaseElSwitch v-model="formData.enable" inactive-text="關閉" active-text="開啟" />
      </BaseElFormItem>
      <BaseElFormItem label="第三方串接發票" prop="platform">
        <BaseElSelect v-model="formData.platform" placeholder="請輸入">
          <BaseElSelectOption
            v-for="platform in invoice3rdPlatformConfig"
            :key="platform.value"
            :value="platform.value"
            :label="platform.label"
          />
        </BaseElSelect>
      </BaseElFormItem>
      <BaseElFormItem label="MerchantID" prop="merchantID">
        <BaseElInput v-model="formData.merchantID" placeholder="請輸入" />
      </BaseElFormItem>
      <BaseElFormItem label="Hash Key" prop="hashKey">
        <BaseElInput v-model="formData.hashKey" placeholder="請輸入" />
      </BaseElFormItem>
      <BaseElFormItem label="Hash IV" prop="hashIV">
        <BaseElInput v-model="formData.hashIV" placeholder="請輸入" />
      </BaseElFormItem>
    </BaseElForm>
  </BaseDialog>
</template>

<script>
import BaseDialog from '@/components/Dialog/BaseDialog.vue'
import { useBaseForm } from '@/use/useForm'
import { computed, defineComponent, onBeforeMount } from 'vue'
import { get, map } from 'lodash'
import { passValue } from '@/utils/helper'
import { useShop } from '@/use/shop'
import notifyMessage from '@/config/notifyMessage'
import { isDigitRules, noEmptyRules, rangeRules } from '@/validation'
import { invoice3rdPlatformConfig } from '@/config/memberShop'
import { UpdateReservationCheckoutInvoiceConfig } from '@/api/reservation'

export default defineComponent({
  name: 'EditReservationBasicSettingsModal',
  components: { BaseDialog },
  props: {
    configData: { type: Object, default: () => ({}) },
  },
  setup(props, { emit }) {
    const { formRef, initFormData, formData, checkForm, loading } = useBaseForm()
    const { shopId } = useShop()

    const formRules = computed(() => {
      return {
        enable: [noEmptyRules()],
        platform: [noEmptyRules()],
        merchantID: [noEmptyRules()],
        hashKey: [noEmptyRules()],
        hashIV: [noEmptyRules()],
      }
    })

    const onSubmit = async () => {
      if (loading.value) return
      loading.value = true
      if (!(await checkForm(formRef.value))) return
      const [, errMsg] = await UpdateReservationCheckoutInvoiceConfig({
        shopId: shopId.value,
        enable: formData.enable,
        platform: formData.platform,
        invoiceApiConfig: {
          merchantID: formData.merchantID,
          hashKey: formData.hashKey,
          hashIV: formData.hashIV,
        },
      })
      loading.value = false
      if (errMsg) {
        window.$message.error(errMsg)
        return
      }
      window.$message.success(notifyMessage.updateSuccess)
      emit('refresh')
      emit('close')
    }

    onBeforeMount(() => {
      initFormData({
        enable: get(props.configData, 'enable', false),
        platform: get(props.configData, 'platform', ''),
        merchantID: get(props.configData, 'invoiceApiConfig.merchantID', ''),
        hashKey: get(props.configData, 'invoiceApiConfig.hashKey', ''),
        hashIV: get(props.configData, 'invoiceApiConfig.hashIV', ''),
      })
    })

    return { formRef, onSubmit, loading, formRules, formData, invoice3rdPlatformConfig }
  },
})
</script>

<style lang="postcss" scoped></style>
