<template>
  <section class="section-block">
    <SectionTitle title="發票設定" @edit="modal.edit = true" />
    <BaseElForm label-position="left" label-width="220px">
      <BaseElFormItem label="開放串接第三方發票"> {{ displayData.enabled }} </BaseElFormItem>
      <BaseElFormItem label="第三方串接發票"> {{ displayData.platform }} </BaseElFormItem>
    </BaseElForm>

    <EditReservationCheckoutInvoiceSettingsModal
      v-if="modal.edit"
      :configData="configData"
      @close="modal.edit = false"
      @refresh="refresh"
    />
  </section>
</template>

<script>
import { defineComponent, computed, reactive, ref, onBeforeMount } from 'vue'
import SectionTitle from '@/components/Title/SectionTitle.vue'
import EditReservationCheckoutInvoiceSettingsModal from './EditReservationCheckoutInvoiceSettingsModal.vue'
import { GetReservationCheckoutInvoiceConfig } from '@/api/reservation'
import { get } from 'lodash'
import { useShop } from '@/use/shop'
import { invoice3rdPlatformConfig } from '@/config/memberShop'

export default defineComponent({
  name: 'ReservationCheckoutInvoiceSettingsBlock',
  components: { SectionTitle, EditReservationCheckoutInvoiceSettingsModal },
  setup(props) {
    const configData = ref({})
    const { shopId } = useShop()
    const modal = reactive({
      edit: false,
    })
    const displayData = computed(() => {
      return {
        enabled: get(configData.value, 'enable') ? '開啟' : '關閉',
        platform:
          get(invoice3rdPlatformConfig, `${get(configData.value, 'platform')}.label`) || '尚未設定',
      }
    })

    const refresh = async () => {
      const [data, errMsg] = await GetReservationCheckoutInvoiceConfig({ shopId: shopId.value })
      if (errMsg) {
        window.$message.error(errMsg)
        return
      }
      configData.value = data
    }

    onBeforeMount(async () => {
      refresh()
    })

    return {
      modal,
      configData,
      displayData,
      refresh,
    }
  },
})
</script>

<style lang="postcss" scoped></style>
