<template>
  <div>
    <BaseElFormItem label="jkosStoreId" prop="jkos.jkosStoreId">
      <BaseElInput v-model="syncForm.jkosStoreId" placeholder="請輸入 MerchantId" />
    </BaseElFormItem>
    <BaseElFormItem label="jkosApiKey" prop="jkos.jkosApiKey">
      <BaseElInput v-model="syncForm.jkosApiKey" placeholder="請輸入 API Key" />
    </BaseElFormItem>
    <BaseElFormItem label="jkosSercertKey" prop="jkos.jkosSercertKey">
      <BaseElInput v-model="syncForm.jkosSercertKey" placeholder="請輸入 Digest" />
    </BaseElFormItem>
  </div>
</template>

<script>
export default {
  name: 'JkosPayForm',
  props: {
    formData: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    syncForm: {
      get () {
        return this.formData
      },
      set (v) {
        return this.$emit('update', v)
      },
    },
  },
}
</script>

<style lang="postcss" scoped></style>
