<template>
  <section :v-loading="loading" class="settings-section section-block">
    <div>
      <SectionTitle title="訂金設定" @edit="openEditDepositDialog" />
      <div>
        <BaseElForm
          :model="formData"
          label-position="left"
          label-width="250px"
          @submit.prevent.native="updateReservationParameter"
        >
          <!-- <BaseElFormItem label="是否開啟訂金：">
            <span>{{ configData.enable ? '啟用' : '關閉' }}</span>
          </BaseElFormItem> -->

          <BaseElFormItem v-if="useClassTicketCredit" label="堂票預約免付訂金：">
            <span>{{ configData.enableClassTicketCredit ? '啟用' : '關閉' }}</span>
          </BaseElFormItem>

          <BaseElFormItem label="收費模式設定：">
            <span>{{ feeModel }}</span>
          </BaseElFormItem>

          <BaseElFormItem label="收費對象設定：">
            <span>{{ feeTarget }}</span>
          </BaseElFormItem>

          <BaseElFormItem label="收取訂金服務項目設定：">
            <span>{{ services }}</span>
          </BaseElFormItem>

          <BaseElFormItem label="收取訂金附加服務設定：">
            <span>{{ attachServices }}</span>
          </BaseElFormItem>

          <BaseElFormItem label="收取訂金期間設定：">
            <span>{{ dateRange }}</span>
          </BaseElFormItem>
        </BaseElForm>
      </div>
    </div>

    <!-- <div>
      <SectionTitle hideTitleStick btn="新增" @edit="handlePayment('create')" />
      <BaseTable :data="payments">
        <BaseElTableColumn label="名稱" width="200" align="center">
          <template slot-scope="scope">
            {{ depositPaymentTypes[scope.row.type].name }}
          </template>
        </BaseElTableColumn>
        <BaseElTableColumn label="啟用" align="center">
          <template slot-scope="scope">
            <Tag :type="tagType(scope.row.enable)">
              {{ scope.row.enable ? '是' : '否' }}
            </Tag>
          </template>
        </BaseElTableColumn>
        <BaseElTableColumn label="操作" fixed="right" align="center" width="200">
          <template slot-scope="scope">
            <TableEditBtnGroup
              @edit="handlePayment('edit', scope.row)"
              @delete="handlePayment('delete', scope.row)"
            />
          </template>
        </BaseElTableColumn>
      </BaseTable>
    </div> -->

    <EditDepositDialog
      v-if="showEditDialog"
      :formData.sync="formData"
      @close="showEditDialog = false"
      @update="updateConfig"
    />

    <!-- <EditDepositPaymentDialog
      v-if="showEditPaymentDialog"
      :selectPayment="selectPayment"
      :type="paymentDialogType"
      @refresh="$emit('refresh')"
      @close="showEditPaymentDialog = false"
    /> -->

    <DeleteDialog
      v-if="deleteDialog"
      title="提醒"
      content="刪除後將無法復原，確定要刪除？"
      width="40%"
      @close="deleteDialog = false"
      @delete="deleteDepositPayment"
    />
  </section>
</template>

<script>
import { defineComponent, computed, reactive } from 'vue'
import DeleteDialog from '@/components/Dialog/DeleteDialog.vue'
import EditDepositDialog from './EditDepositDialog.vue'
import { depositPaymentTypes } from '@/config/deposit'
import { UpdateDepositConfig, DeleteDepositPayment } from '@/api/deposit'
// import tableMixin from '@/mixin/table'
import { mapGetters } from 'vuex'
import { map, get } from 'lodash'
import dayjs from '@/lib/dayjs'
import { usePermissions } from '@/use/permissions'

export default defineComponent({
  name: 'DepositSettings',
  // mixins: [tableMixin],
  components: { EditDepositDialog, DeleteDialog },
  props: {
    configData: { type: Object, default: () => ({}) },
    payments: { type: Array, default: () => [] },
  },
  setup(props, { emit }) {
    const { checkAction } = usePermissions()
    const useClassTicketCredit = computed(() =>
      checkAction('admin.appointmentDepositConfig.enableClassTicketCredit'),
    )

    const feeModel = computed(() => {
      let label
      if (get(props.configData, 'feeModel') === 'serviceCount') {
        const price = get(props.configData, 'feeModelServiceCountPrice')
        label = `固定金額：$${price}`
      }
      if (get(props.configData, 'feeModel') === 'totalPrice') {
        const ratio = get(props.configData, 'feeModelTotalPriceRatio')
        label = `固定比率：${ratio * 100}%`
      }
      return label
    })

    const formData = reactive({
      enable: true,
      paymentMode: 'cash',
      cash: null,
      percentege: null,
      target: 'all',
      memberInclude: [], // 包含會員標籤
      memberExclude: [], // 排除會員標籤
      services: 'all',
      servicesExclude: [], // 免收訂金服務
      servicesInclude: [], // 胥收訂金服務
      attachServices: 'all',
      attachServicesInclude: [],
      attachServicesExclude: [],
      enableDateRange: false,
      dateRange: null,
      enableClassTicketCredit: false,
    })
    return { formData, useClassTicketCredit, feeModel }
  },
  data: () => ({
    loading: false,
    depositPaymentTypes,
    showEditDialog: false,
    showEditPaymentDialog: false,
    paymentDialogType: 'create',
    deleteDialog: false,
    selectPayment: null,
    depositPayments: [],
    depositConfig: {},
  }),
  computed: {
    ...mapGetters(['shop']),
    compactData() {
      const form = this.formData
      const data = {}
      data.enable = form.enable
      data.enableClassTicketCredit = form.enableClassTicketCredit
      // 收費方式
      if (form.paymentMode === 'cash') {
        data.feeModel = 'serviceCount'
        data.feeModelServiceCountPrice = form.cash
      }
      if (form.paymentMode === 'percentege') {
        data.feeModel = 'totalPrice'
        data.feeModelTotalPriceRatio = form.percentege / 100
      }
      // 收費對象
      data.specifyMTags = []
      data.ignoreMTags = []
      if (form.target === 'all') {
        data.enableIgnoreMTag = false
        data.enableSpecifyMTag = false
        // data.specifyMTags = []
        // data.ignoreMTags = []
      }
      if (form.target === 'advance') {
        if (form.memberInclude.length) {
          data.enableSpecifyMTag = true
          data.specifyMTags = map(form.memberInclude, 'id')
        } else data.enableSpecifyMTag = false
        if (form.memberExclude.length) {
          data.enableIgnoreMTag = true
          data.ignoreMTags = map(form.memberExclude, 'id')
        } else data.enableIgnoreMTag = false
      }
      // 服務項目
      if (form.services === 'include') {
        data.enableSpecifyService = true
        data.enableIgnoreService = false
        data.specifyServices = map(form.servicesInclude, 'id')
        data.ignoreServices = []
      }
      if (form.services === 'exclude') {
        data.enableIgnoreService = true
        data.enableSpecifyService = false
        data.ignoreServices = map(form.servicesExclude, 'id')
        data.specifyServices = []
      }
      // 附加服務
      if (form.attachServices === 'all') {
        data.enableIgnoreServiceAttach = false
        data.enableSpecifyServiceAttach = true
        data.specifyServiceAttaches = []
        data.ignoreServiceAttaches = []
      }
      if (form.attachServices === 'include') {
        data.enableSpecifyServiceAttach = true
        data.enableIgnoreServiceAttach = false
        data.specifyServiceAttaches = map(form.attachServicesInclude, 'id')
        data.ignoreServiceAttaches = []
      }
      if (form.attachServices === 'exclude') {
        data.enableIgnoreServiceAttach = true
        data.enableSpecifyServiceAttach = false
        data.ignoreServiceAttaches = map(form.attachServicesExclude, 'id')
        data.specifyServiceAttaches = []
      }
      // 時間範圍
      if (form.enableDateRange) {
        data.enableSpecifyTimeRange = true
        data.specifyTimeRangeStart = form.dateRange[0]
        data.specifyTimeRangeEnd = form.dateRange[1]
      }
      if (!form.enableDateRange) {
        data.enableSpecifyTimeRange = false
      }

      return data
    },

    feeTarget() {
      const config = this.configData
      let label
      if (!config.enableIgnoreMTag && !config.enableSpecifyMTag) label = '所有會員'

      if (!config.enableIgnoreMTag && config.enableSpecifyMTag && config.SpecifyMTags.length) {
        const include = map(config.SpecifyMTags, 'name')
        label = `進階標籤會員 包含：${include.join('、')}`
      }

      if (config.enableIgnoreMTag && !config.enableSpecifyMTag && config.IgnoreMTags.length) {
        const exclude = map(config.IgnoreMTags, 'name')
        label = `進階標籤會員 排除：${exclude.join('、')}`
      }

      if (config.enableIgnoreMTag && config.enableSpecifyMTag) {
        const include = map(config.SpecifyMTags, 'name')
        const exclude = map(config.IgnoreMTags, 'name')
        if (include.length && exclude.length) {
          label = `進階標籤會員 包含：${include.join('、')}｜排除：${exclude.join('、')}`
        }
        if (!include.length && exclude.length) {
          label = `進階標籤會員 排除：${exclude.join('、')}`
        }
        if (include.length && !exclude.length) {
          label = `進階標籤會員 包含：${include.join('、')}`
        }
      }
      return label
    },

    services() {
      const config = this.configData
      let label
      if (
        config.enableIgnoreService &&
        !config.enableSpecifyService &&
        !config.SpecifyServices.length
      ) {
        label = '所有服務'
      }
      if (
        config.enableIgnoreService &&
        !config.enableSpecifyService &&
        config.IgnoreServices.length
      ) {
        const exclude = map(config.IgnoreServices, 'name')
        label = `全部服務但排除：${exclude.join('、')}`
      }
      if (
        !config.enableIgnoreService &&
        config.enableSpecifyService &&
        config.SpecifyServices.length
      ) {
        const include = map(config.SpecifyServices, 'name')
        label = `特定服務：${include.join('、')}`
      }
      if (!config.enableSpecifyService && !config.enableIgnoreService) {
        label = '尚未設定'
      }

      return label
    },

    attachServices() {
      const config = this.configData
      let label
      if (
        !config.enableIgnoreServiceAttach &&
        config.enableSpecifyServiceAttach &&
        !config.SpecifyServiceAttaches.length
      ) {
        label = '全部皆免收定金'
      }
      if (
        config.enableIgnoreServiceAttach &&
        !config.enableSpecifyServiceAttach &&
        !config.IgnoreServiceAttaches.length
      ) {
        label = '所有附加服務'
      }

      if (
        config.enableIgnoreServiceAttach &&
        !config.enableSpecifyServiceAttach &&
        config.IgnoreServiceAttaches.length
      ) {
        const exclude = map(config.IgnoreServiceAttaches, 'name')
        label = `全部服務但排除：${exclude.join('、')}`
      }

      if (
        !config.enableIgnoreServiceAttach &&
        config.enableSpecifyServiceAttach &&
        config.SpecifyServiceAttaches.length
      ) {
        const include = map(config.SpecifyServiceAttaches, 'name')
        label = `特定服務：${include.join('、')}`
      }
      if (!config.enableIgnoreServiceAttach && !config.enableSpecifyServiceAttach) {
        label = '尚未設定'
      }
      return label
    },
    dateRange() {
      const config = this.configData
      if (!config.enableSpecifyTimeRange) return '關閉'
      if (!config.specifyTimeRangeStart && !config.specifyTimeRangeEnd) return '關閉'
      const formatPattern = 'YYYY/MM/DD HH:mm'
      const start = dayjs(config.specifyTimeRangeStart).format(formatPattern)
      const end = dayjs(config.specifyTimeRangeEnd).format(formatPattern)
      return `${start} ~ ${end}`
    },
  },
  async mounted() {
    await this.refresh()
  },
  methods: {
    openEditDepositDialog() {
      this.syncConfigFormData()
      this.showEditDialog = true
    },
    tagType(val) {
      let type = 'info'
      // if(! val) type = 'danger'
      if (val) type = 'action'
      return type
    },

    async updateConfig() {
      const [, err] = await UpdateDepositConfig({
        shopId: this.shop,
        ...this.compactData,
      })
      if (err) {
        this.$message.error(err)
        return
      }
      this.showEditDialog = false
      this.$message.success('更新成功！')
      this.$emit('refresh')
    },

    async deleteDepositPayment() {
      this.deleteDialog = false
      const [, err] = await DeleteDepositPayment({
        shopId: this.shop,
        id: this.selectPayment.id,
      })
      if (err) return this.$message.error(err)
      this.$message.success('更新成功！')
      this.$emit('refresh')
    },

    async handlePayment(type, row) {
      this.paymentDialogType = type
      if (type === 'delete') {
        this.selectPayment = row
        this.deleteDialog = true
      }
      if (type === 'create') {
        this.selectPayment = null
        this.showEditPaymentDialog = true
      }
      if (type === 'edit') {
        this.selectPayment = row
        this.showEditPaymentDialog = true
      }
    },

    syncConfigFormData() {
      const config = this.configData
      const setData = (key, data, options = {}) => {
        this.$set(options.target || this.formData, key, data)
      }
      setData('enable', config.enable)
      setData('enableClassTicketCredit', config.enableClassTicketCredit)
      let paymentMode
      if (config.feeModel === 'serviceCount') paymentMode = 'cash'
      if (config.feeModel === 'totalPrice') paymentMode = 'percentege'
      setData('paymentMode', paymentMode)
      setData('cash', config.feeModelServiceCountPrice)
      setData('percentege', config.feeModelTotalPriceRatio * 100)

      // 對象
      let target
      if (!config.enableIgnoreMTag && !config.enableSpecifyMTag) target = 'all'
      else target = 'advance'
      // if (!config.enableSpecifyMTag && config.enableIgnoreMTag) target = 'advance'
      // if (config.enableSpecifyMTag && !config.enableIgnoreMTag) target = 'advance'
      // if (config.enableSpecifyMTag && config.enableIgnoreMTag) target = 'advance'
      setData('target', target)
      setData('memberExclude', config.IgnoreMTags)
      setData('memberInclude', config.SpecifyMTags)

      // 服務項目
      let services
      if (!config.enableSpecifyService && !config.enableIgnoreService) services = 'exclude'
      if (!config.enableSpecifyService && config.enableIgnoreService) services = 'exclude'
      if (config.enableSpecifyService && !config.enableIgnoreService) services = 'include'
      setData('services', services)
      setData('servicesExclude', config.IgnoreServices)
      setData('servicesInclude', config.SpecifyServices)

      // 附加服務項目
      let attachServices
      // if (!config.enableIgnoreServiceAttach && !config.enableSpecifyServiceAttach) attachServices = 'all'
      if (!config.enableIgnoreServiceAttach && !config.enableSpecifyServiceAttach) {
        attachServices = 'all'
      }
      if (
        !config.enableIgnoreServiceAttach &&
        config.enableSpecifyServiceAttach &&
        !config.SpecifyServiceAttaches.length
      ) {
        attachServices = 'all'
      }
      if (
        !config.enableIgnoreServiceAttach &&
        config.enableSpecifyServiceAttach &&
        config.SpecifyServiceAttaches.length
      ) {
        attachServices = 'include'
      }
      if (
        config.enableIgnoreServiceAttach &&
        !config.enableSpecifyServiceAttach &&
        config.IgnoreServiceAttaches.length
      ) {
        attachServices = 'exclude'
      }
      if (
        config.enableIgnoreServiceAttach &&
        !config.enableSpecifyServiceAttach &&
        !config.IgnoreServiceAttaches.length
      ) {
        attachServices = 'exclude'
      }

      setData('attachServices', attachServices)
      setData('attachServicesExclude', config.IgnoreServiceAttaches)
      setData('attachServicesInclude', config.SpecifyServiceAttaches)

      // 日期
      setData('enableDateRange', config.enableSpecifyTimeRange)
      setData('dateRange', [])
      const start = new Date(config.specifyTimeRangeStart)
      const end = new Date(config.specifyTimeRangeEnd)
      if (start.toString() === 'Invalid Date' || end.toString() === 'Invalid Date') {
        setData('dateRange', null)
      } else {
        setData(0, start, { target: this.formData.dateRange })
        setData(1, end, { target: this.formData.dateRange })
      }
    },

    async refresh() {
      this.syncConfigFormData()
    },
  },
})
</script>

<style></style>
